//
// magicline
//

.init-magic-line,
.magic-line-inner {
  position: relative;
}
.magic-line {
  z-index: -1;
  position: absolute;
}
.magic-line-css-transition {
  transition: all 200ms ease-in-out;
}

.magic-line-menu,
.magic-line-menu-pill,
.magic-line-inner,
.magic-line-inner ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.magic-line-inner ul {
  list-style: none;
}
.magic-line-menu a,
.magic-line-menu-pill a {
  display: block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  border-radius: 6.25rem;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  &.active {
  }
}
.magic-line {
  border-radius: 6.25rem;
}

.navbar-dark {
  .magic-line-menu a,
  .magic-line-menu-pill a {
    color: $white;
  }
  .magic-line {
    background: rgba($gray-100, .2);
  }
}
.navbar-light {
  .magic-line-menu a,
  .magic-line-menu-pill a {
    color: $black;
  }
  .magic-line {
    background: rgba($gray-900, .1);
  }
}
